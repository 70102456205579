import { isTopic } from '@tunein/web-common';
import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { unifiedEvents } from 'src/client/api';
import {
  closeNowPlayingDialog,
  openUpsellAndTrackActivity,
} from 'src/common/actions/dialog';
import { logContentCardClick } from 'src/common/actions/logging';
import { setBreadcrumbId } from 'src/common/actions/reporting';
import { labels } from 'src/common/constants/analytics/categoryActionLabel/subscribe';
import { selectIsMobile } from 'src/common/selectors/app';
import {
  selectExperiment,
  selectIsUnifiedEventsContentReportingEnabled,
} from 'src/common/selectors/config';
import { selectIsNowPlayingDialogOpen } from 'src/common/selectors/dialog';
import { selectBreadcrumbId } from 'src/common/selectors/reporting';
import { brazeLogContentCardClick } from 'src/common/utils/braze/brazeHelper';
import { doesContainerSupportContentReporting } from 'src/common/utils/guideItem/doesContainerSupportContentReporting';
import { BYPASS_UPSELL_DIALOG_ENABLED } from '../../../constants/experiments/dialog';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import debounce from '../../../utils/debounce';
import getGuideItemProducts from '../../../utils/guideItem/getGuideItemProducts';
import getSubscribeEventLabel from '../../../utils/subscription/getSubscribeEventLabel';
import createAndOpenSubscribeLink from '../../utils/createAndOpenSubscribeLink';
import css from './guide-item.module.scss';

const SOURCE = 'locked.tile';

export function LockedTile({
  guideItem,
  children,
  actions,
  style,
  className,
  dataTestId = 'lockedItem',
  isClickHandlerEnabled = true,

  // mapStateToProps
  isMobile,
  isNowPlayingDialogOpen,
  isPremiumUpsellBypassEnabled,
}) {
  const history = useHistory();
  const { location } = useContext(LocationAndLocalizationContext);
  const isUnifiedEventsContentReportingEnabled = useSelector(
    selectIsUnifiedEventsContentReportingEnabled,
  );
  const breadcrumbId = useSelector(selectBreadcrumbId);

  const handleClick = debounce(() => {
    if (isUnifiedEventsContentReportingEnabled) {
      if (!breadcrumbId) {
        actions.setBreadcrumbId();
      }
      if (
        doesContainerSupportContentReporting(
          guideItem.reporting?.container?.type,
        )
      ) {
        unifiedEvents.reportContentClicked(guideItem);
      }
    }

    if (guideItem.brazeCardId) {
      brazeLogContentCardClick(guideItem, actions);
    }

    if (isMobile && isNowPlayingDialogOpen) {
      actions.closeNowPlayingDialog();
    }

    if (isPremiumUpsellBypassEnabled) {
      createAndOpenSubscribeLink(guideItem, location, null, SOURCE, history);
      return;
    }

    let parentGuideId;
    let topicGuideId;

    const isTopicGuideId = isTopic(guideItem.guideId);

    if (isTopicGuideId) {
      parentGuideId = get(
        guideItem,
        'properties.parentProgram.destinationInfo.id',
      );
      // we want the topicGuideId to be set only when guide id is a topic
      topicGuideId = guideItem.guideId;
    }
    const eventLabel = getSubscribeEventLabel(
      labels.profileUpsellDialog,
      location,
    );

    actions.openUpsellAndTrackActivity(
      eventLabel,
      parentGuideId || guideItem.guideId,
      topicGuideId,
      getGuideItemProducts(guideItem),
      guideItem.image,
      guideItem.title,
    );
  });

  return (
    <div
      data-testid={dataTestId}
      className={classNames([className, css.guideItemLink])}
      style={style}
      onClick={isClickHandlerEnabled ? handleClick : null}
    >
      {children}
    </div>
  );
}

LockedTile.propTypes = {
  guideItem: PropTypes.object,
  children: PropTypes.node.isRequired,
  actions: PropTypes.object.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isClickHandlerEnabled: PropTypes.bool,
  // mapStateToProps
  isMobile: PropTypes.bool.isRequired,
  isNowPlayingDialogOpen: PropTypes.bool.isRequired,
  isPremiumUpsellBypassEnabled: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openUpsellAndTrackActivity,
        closeNowPlayingDialog,
        logContentCardClick,
        setBreadcrumbId,
      },
      dispatch,
    ),
  };
}

const mapStateToProps = (state) => ({
  isMobile: selectIsMobile(state),
  isNowPlayingDialogOpen: selectIsNowPlayingDialogOpen(state),
  isPremiumUpsellBypassEnabled: selectExperiment(
    state,
    BYPASS_UPSELL_DIALOG_ENABLED,
    false,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LockedTile);
